import React, { Fragment, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Dropdown,
  ProgressBar,
} from "react-bootstrap"; 
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import useSWR from 'swr';
const fetcher = (...args) => fetch(...args).then(res => res.json())
const urlApi = 'https://fortress.bfi.nda.co.id/api/export/laporan/excel/bulanan/'+new Date().getFullYear();
const ExcelExportData = [{"Nama" : "ivan", "Last Name" : "Edwin", "Age" : "25"}, { "Nama" : "evan", "Last Name" : "Edvin", "Age" : "21" }];


const ExportData = () => {

  const tableRef = useRef(null);

  const { data, error, isLoading } = useSWR(urlApi, fetcher)

  if (error) return <div>failed to load</div>
  if (isLoading) return <div>loading...</div>

  const chackbox = document.querySelectorAll(".bs_exam_topper input");
  const motherChackBox = document.querySelector(".bs_exam_topper_all input");
  const chackboxFun = (type) => {
    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  return (
    <Fragment>
      <PageTitle motherMenu="Export" activeMenu="Xls" />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Data BFI</Card.Title>
            </Card.Header>
            <Card.Body>
            
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-primary"
                table="table-to-xls"
                filename="Export Laporan"
                sheet="Sheet 1"
                buttonText="export as XLS"/>
              <Table responsive hover className="header-border verticle-middle">
                {/* <table id="table-to-xls"> */}
                  <thead>
                    <tr>
                      <th scope="col">Bulan</th>
                      <th scope="col">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Februari</th>
                      <td>{data.februari}</td>
                    </tr>
                    <tr>
                      <th>Januari</th>
                      <td>{data.januari}</td>
                    </tr>
                    <tr>
                      <th>Maret</th>
                      <td>{data.maret}</td>
                    </tr>
                    <tr>
                      <th>April</th>
                      <td>{data.april}</td>
                    </tr>
                    <tr>
                      <th>Mei</th>
                      <td>{data.mei}</td>
                    </tr>
                    <tr>
                      <th>Juni</th>
                      <td>{data.juni}</td>
                    </tr>
                    <tr>
                      <th>Juli</th>
                      <td>{data.july}</td>
                    </tr>
                    <tr>
                      <th>Agustus</th>
                      <td>{data.agustus}</td>
                    </tr>
                    <tr>
                      <th>September</th>
                      <td>{data.september}</td>
                    </tr>
                    <tr>
                      <th>Oktober</th>
                      <td>{data.oktober}</td>
                    </tr>
                    <tr>
                      <th>November</th>
                      <td>{data.november}</td>
                    </tr>
                    <tr>
                      <th>Desember</th>
                      <td>{data.desember}</td>
                    </tr>
                  </tbody>
                {/* </table> */}
              </Table>
            </Card.Body>
          </Card>
            <table id="table-to-xls" style={{display:'none'}}>
            <tr>
              <td scope="col">Bulan</td>
              <td scope="col">Data</td>
            </tr>
            <tr>
              <td>Februari</td>
              <td>{data.februari}</td>
            </tr>
            <tr>
              <td>Januari</td>
              <td>{data.januari}</td>
            </tr>
            <tr>
              <td>Maret</td>
              <td>{data.maret}</td>
            </tr>
            <tr>
              <td>April</td>
              <td>{data.april}</td>
            </tr>
            <tr>
              <td>Mei</td>
              <td>{data.mei}</td>
            </tr>
            <tr>
              <td>Juni</td>
              <td>{data.juni}</td>
            </tr>
            <tr>
              <td>Juli</td>
              <td>{data.july}</td>
            </tr>
            <tr>
              <td>Agustus</td>
              <td>{data.agustus}</td>
            </tr>
            <tr>
              <td>September</td>
              <td>{data.september}</td>
            </tr>
            <tr>
              <td>Oktober</td>
              <td>{data.oktober}</td>
            </tr>
            <tr>
              <td>November</td>
              <td>{data.november}</td>
            </tr>
            <tr>
              <td>Desember</td>
              <td>{data.desember}</td>
            </tr>
          </table>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ExportData;
