import React, { useContext, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
//Images
//Components
import { ThemeContext } from "../../../context/ThemeContext";
import InvoiceCard from "./rekap_data";
import InvoiceCardBfi from "./rekap_data_bfi";
import InvoiceCardWom from "./rekap_data_wom";
import InvoiceCardMyb from "./rekap_data_myb";
// import InvoiceCardBatumbu from "./rekap_data_myb";

import acc from "../../../images/natadana/acc.png";
import bfi from "../../../images/natadana/bfi.png";
import wom from "../../../images/natadana/wom.jpg";
import myb from "../../../images/natadana/maybank.jpg";
import bcafImage from "../../../images/natadana/bcaf.png";
import batumbuImage from "../../../images/natadana/batumbu.png";
import { RekapDataBcaf } from "./rekap_data_bcaf";
import { RekapDataBatumbu } from "./rekap_data_batumbu";

const Home = () => {
  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);

  return (
    <>
      <Row>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img src={acc} className="rounded" alt="" />
            </Card.Header>
            <Card.Body>
              <div className="row invoice-card-row">
                <InvoiceCard />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img src={bfi} className="rounded" alt="" />
            </Card.Header>
            <Card.Body>
              <div className="row invoice-card-row">
                <InvoiceCardBfi />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={wom}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <div className="row invoice-card-row">
                <InvoiceCardWom />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={myb}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <div className="row invoice-card-row">
                <InvoiceCardMyb />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={bcafImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <div className="row invoice-card-row">
                <RekapDataBcaf />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Header>
              <img
                src={batumbuImage}
                className="rounded"
                alt=""
                width={366}
                height={96}
              />
            </Card.Header>
            <Card.Body>
              <div className="row invoice-card-row">
                <RekapDataBatumbu />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default Home;
