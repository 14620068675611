import React, { useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function DatePickYear(props) {
   const [selectedDate, handleDateChange] = useState(new Date());

   return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
         <DatePicker
            autoOk
            label=""
            clearable
            format="yyyy"
            openTo="year"
            views={["year"]}
            minDate={new Date('2015-01-01')}
            disableFuture
            value={selectedDate}
            onChange={handleDateChange}
         />
      </MuiPickersUtilsProvider>
   );
}

export default DatePickYear;
