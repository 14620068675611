/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from "./Logout";
/// Image
import profile from "../../../images/profile/pic1.jpg";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    // btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let deshBoard = [
      "",
      "dashboard",
      "dashboard-dark",
      "dashboard-light",
      "my-wallets",
      "invoices",
      "cards-center",
      "transaction-history",
      "transaction-details",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ];

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <Dropdown as="li" className="nav-item dropdown header-profile">
            <Dropdown.Toggle
              variant=""
              as="a"
              className="nav-link i-false c-pointer"
              role="button"
              data-toggle="dropdown"
            >
              <img src={profile} width={20} alt="" />
              <div className="header-info ms-3">
                <span className="font-w600 ">
                  Hi,<b>Superadmin</b>
                </span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu
              align="right"
              className="mt-2 dropdown-menu dropdown-menu-end"
            >
              <LogoutPage />
            </Dropdown.Menu>
          </Dropdown>
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-025-dashboard"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "dashboard" ? "mm-active" : ""}`}
                  to="/dashboard"
                >
                  {" "}
                  ✰ Dashboard
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "dashboard-light" ? "mm-active" : ""}`}
                  to="/dashboard-light"
                >
                  {" "}
                  ✰ Dashboard Light
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "dashboard-dark" ? "mm-active" : ""}`}
                  to="/dashboard-dark"
                >
                  {" "}
                  ✰ Dashboard Dark
                </Link>
              </li>
            </ul>
          </li>

          <li className={`${charts.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-041-graph"></i>
              <span className="nav-text">Charts</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "Bulan" ? "mm-active" : ""}`}
                  to="/Bulan"
                >
                  ✰ Bulan
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "Hari" ? "mm-active" : ""}`}
                  to="/Hari"
                >
                  ✰ Hari
                </Link>
              </li>
            </ul>
          </li>

          <li className={`${charts.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-072-printer"></i>
              <span className="nav-text">Export</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "export" ? "mm-active" : ""}`}
                  to="/export"
                >
                  ✰ Acc
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "export-bfi" ? "mm-active" : ""}`}
                  to="/export-bfi"
                >
                  ✰ Bfi
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "export-wom" ? "mm-active" : ""}`}
                  to="/export-wom"
                >
                  ✰ WOM
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "export-myb" ? "mm-active" : ""}`}
                  to="/export-myb"
                >
                  ✰ Maybank
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "export-bcaf" ? "mm-active" : ""}`}
                  to="/export-bcaf"
                >
                  ✰ Bca Finance
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "export-batumbu" ? "mm-active" : ""}`}
                  to="/export-batumbu"
                >
                  ✰ Batumbu Finance
                </Link>
              </li>
            </ul>
          </li>
          <hr></hr>
        </MM>
        <div className="copyright">
          <p>
            <strong> Admin Dashboard</strong> © 2023 All Rights Reserved
          </p>
          <p className="fs-12">
            Made with <span className="heart"></span> by NATADANA
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
