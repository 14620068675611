import React from "react";
import { Bar } from "react-chartjs-2";

import useSWR from "swr";
const fetcher = (...args) => fetch(...args).then((res) => res.json());
const urlApi =
  "https://fortress.batumbu.nda.co.id/api/data_bulan/" + new Date().getFullYear();

export default function BatumbuReportMonth() {
  const { data, error, isLoading } = useSWR(urlApi, fetcher);

  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;
  var data1 = {
    defaultFontFamily: "Poppins",
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ],
    datasets: [
      {
        label: "Data Masuk",
        data: [
          data.januari,
          data.februari,
          data.maret,
          data.april,
          data.mei,
          data.juni,
          data.juli,
          data.agustus,
          data.september,
          data.oktober,
          data.november,
          data.desember,
        ],
        borderColor: "rgba(64, 24, 157, 1)",
        borderWidth: "0",
        backgroundColor: "#5bcfc5",
      },
    ],
  };

  var options = {
    legend: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            padding: 10,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 5,
            barPercentage: 0.5,
          },
        },
      ],
    },
  };
  return (
    <>
      <Bar data={data1} options={options} height={150} />;
    </>
  );
}
