import React from "react";
import { Bar } from "react-chartjs-2";

import useSWR from 'swr';
const fetcher = (...args) => fetch(...args).then(res => res.json())
const api1 = 'https://fortress.acc.nda.co.id/api/data_bulan/'+new Date().getFullYear();


function Example() {

  var data = {
      defaultFontFamily: "Poppins",
      labels: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"],
      datasets: [
        {
          label: "Data Masuk",
          data: [25, 20, 60, 41, 66, 45, 80, 0, 0, 0, 0, 0 ],
          borderColor: "#5bcfc5",
          borderWidth: "1",
          backgroundColor: "rgba(91, 207, 193, .5)",
          pointBackgroundColor: "rgba(0, 0, 1128, .3)",
        },
      ],
    };
  
  var options = {
    legend: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 100,
            min: 0,
            stepSize: 20,
            padding: 10,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 5,
          },
        },
      ],
    },
  };
  return (
  <>
    <Bar data={data} options={options} height={150} />;
  </>
  );
}
  
  // class Example extends Component {
  //   render() {
  //     return <Bar data={data} options={options} height={150} />;
  //   }
  // }
  

export default Example;
