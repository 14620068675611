import React from "react";
import { Bar } from "react-chartjs-2";
import useSWR from "swr";
const fetcher = (...args) => fetch(...args).then((res) => res.json());
const bulanIni = +new Date().getMonth() + 1;
const year = new Date().getFullYear();
const urlApi = `https://fortress.acc.nda.co.id/api/data_hari/${year}/${bulanIni}`;

function Acc() {
  const { data, error, isLoading } = useSWR(urlApi, fetcher);
  const day = [];
  const val = [];

  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;
for (let i = 0; i < data.length; i++) {
    day.push(data[i].hari);
    val.push(data[i].jumlah);
  }
  var data1 = {
    defaultFontFamily: "Poppins",
    labels: day,
    datasets: [
      {
        label: "Data Masuk",
        data: val,
      borderColor: "rgba(64, 24, 157, 1)",
        borderWidth: "0",
        backgroundColor: "#5bcfc5",
      },
    ],
  };

  var options = {
    legend: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            padding: 10,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 5,
            barPercentage: 0.5,
          },
        },
      ],
    },
  };
  return (
    <>
      <Bar data={data1} options={options} height={150} />;
    </>
  );
}

export default Acc;
